
import Global from "@/vendor/global"
import Config from "@/vendor/config"
import DistrictHelper from '@/vendor/district';

/**
 * 清除Axios全部请求
 */
const clearAxios = () => {
	Global.axiosRequestTokenList.forEach((item, index) => {
		item.cancel()
	})
}

// 数字转1000,208
const numberToString = (num = 0) => {
	return num.toString().replace(/\d+/, function (n) {
		return n.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
	});
};

// 节流函数
const debounce = (func, delay) => {
	let timer = null
	return function (...args) {
		if (timer) {
			clearTimeout(timer)
		}

		timer = setTimeout(() => {
			func.apply(this, args)
		}, delay)
	}
}

// 获取区县配置
const getCurrentDistrictConfig = () => {
	return Config.district[DistrictHelper.getDistrictId()];
}

export default {
	debounce,
	clearAxios,
	numberToString,
	getCurrentDistrictConfig
}
