import Config from "@/vendor/config"
import Request from "@/api/request.js"

// 商圈行业
const group = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/group', params)
}
// 商圈动态
const posts = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/posts', params)
}
// 我的地址
const myAddress = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/myAddress', params)
}
// 新增地址
const addAddress = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/addAddress', params)
}
// 修改地址
const editAddress = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/editAddress', params)
}
// 地址默认
const setDefaultAddress = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/setDefaultAddress', params)
}
// 删除地址
const delAddress = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/delAddress', params)
}
// 发布商圈
const addPost = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/addPost', params)
}
// 商圈详情
const detail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/detail', params)
}

// 获取默认地址
const getDefaultAddress = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/getDefaultAddress')
}

// 获取地址详情
const getAddressDetail = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/getAddressDetail', params)
}

// 获取商圈发布需要的爱心值
const postLoveValue = () => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/postLoveValue')
}

// 获取商圈发布需要的爱心值
const Delete = (params) => {
	return Request.setBaseUrl(Config.apiUrl).request(Request.post, 'api/business/del',params)
}


export default {
	group,
	posts,
	myAddress,
	addAddress,
	editAddress,
	setDefaultAddress,
	getDefaultAddress,
	getAddressDetail,
	delAddress,
	addPost,
	detail,
	postLoveValue,
	Delete
}
