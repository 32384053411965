import VantVendor from "@/vendor/vant.js"
import WeValidator from 'we-validator'


WeValidator.onMessage = error => {
	VantVendor.Toast(error.msg)
}

WeValidator.addRule('isEmpty', {
	rule: (value, param) => {
		if (value.length < 0 || value <= 0) {
			return true;
		}
		return false;
	}
})

export default WeValidator
